* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 0;
}

html {
  --color-gray-20: rgb(250, 250, 250);
  --color-gray-25: rgb(247, 247, 247);
  --color-gray-50: rgb(237, 237, 237);
  --color-gray-100: rgb(225, 225, 225);
  --color-gray-200: rgb(200, 200, 200);
  --color-gray-300: rgb(175, 175, 175);
  --color-gray-400: rgb(150, 150, 150);
  --color-gray-500: rgb(125, 125, 125);
  --color-gray-600: rgb(100, 100, 100);
  --color-gray-700: rgb(75, 75, 75);
  --color-gray-800: rgb(50, 50, 50);
  --color-gray-900: rgb(25, 25, 25);
  
  --color-primary-50: rgb(253, 224, 200);
  --color-primary-100: rgb(253, 214, 183);
  --color-primary-200: rgb(250, 191, 143);
  --color-primary-400: rgb(223, 159, 41);
  --color-primary-500: rgb(212, 136, 14);
  --color-primary-700: rgb(212, 120, 14);
  --color-primary-200-contrast: rgb(100, 46, 2);
  --color-primary-500-contrast: white;
  
  --color-error-100: rgb(255, 192, 180);
  --color-error-500: rgb(199, 51, 15);
  
  --color-primary-500-bg: rgb(63, 60, 58);
  
  --background-color: rgb(250, 250, 250);

  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-4: 1rem;
  --space-6: 1.5rem;
  --space-8: 2rem;
  
  --border-radius-small: 4px;
  --border-radius-medium: 6px;
  
  --shadow-medium: 0 2px 8px rgba(0, 0, 0, 0.2);
  --shadow-small:  0 1px 4px rgba(0, 0, 0, 0.2);
}