.userInfo__infoContent {
  display: flex;
  align-items: center;
  margin: 1rem;
}

.userInfo__userImage {
  margin-left: 1rem;
}

.userInfo__userDescription {
  margin-left: 1rem;
  cursor: pointer;
}

.userInfo__editText {
  margin-left: 1rem;
  text-decoration: underline;
}

.userInfo__actions {
  text-align: right;
}

.userInfo__closeModalButton {
  text-align: right;
  font-size: 1.5rem;
  cursor: pointer;
}
