.userPage {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 1rem;
}

.userPageItem {
  padding: 0.5rem 0;
}

.userInfo__infoContent {
  display: flex;
  align-items: center;
  /* margin: 1rem; */
}

.userInfoLanguageName {
  padding-left: 1rem;
}

.userInfoProfileImage {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  /* background: #512DA8; */
  background: gray;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  line-height: 2rem;
  /* margin: 20px 0; */
}