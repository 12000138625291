.userInfoUserImageContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.userInfoUserImage {
  height: 50px;
  width: 50px;
  /* height: 3rem;
  width: 3rem; */
  object-fit: cover;
  border-radius: 6px;
}

.userInfoUserImageUpdateRow {
  padding-bottom: 1rem;
}

.userInfoUserImageUpdateTitle {
  font-size: 1.25rem;
  text-align: center;
}

.userInfoUserSelectButton {
  font-size: 1rem;
  padding: 0.25rem;
  border-radius: 4px;
}

.userInfoUserImagePreview {
  height: 100px;
  width: 100px;
  /* height: 3rem;
  width: 3rem; */
  object-fit: cover;
}

.userInfoUserImageUpdateButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}