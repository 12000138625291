.tfaItem {
  padding: 0.5rem 0;
}

.tfaStep {
  padding: 1rem 0;
}

.tfaInput {
  display: block;
  font: inherit;
  padding: 0.25rem 0.5rem;
  width: 50%;
  border-radius: 3px;
  border: 1px solid #ccc;
  background: var(--input-background);
  color: var(--color);
}