.AppStretch {
  min-height: 100vh;
}

.AppContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gLoader {
  font-size: 3rem; 
  color: gray; 
  position: fixed; 
  top: 50%; 
  left: 50%; 
  z-index: 500;
}